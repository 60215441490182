<template>
  <div class="edu-frame" style="background-color: #eeeeee;">
    <edu-nav-bar title="活动列表" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>

    <van-search v-model="pageInfo.condition.activityName" show-action placeholder="请输入搜索关键词" @search="onSearch">
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div style="height: calc(100% - 54px - 46px);overflow: auto;background: #fafafa;">
<!--      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img class="swipe-img" src="../../../statics/images/ad1.jpg">
        </van-swipe-item>
        <van-swipe-item>
          <img class="swipe-img" src="../../../statics/images/ad1.jpg">
        </van-swipe-item>
      </van-swipe>-->
      <van-list
          class="activity-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in list" :key="item.activitySettingMasterID" class="activity-item" @click="toBaodu(item)">
          <div class="item-title">
<!--            <div class="item-title-line"></div>-->
            {{item.activityName}}
          </div>
<!--          <div class="item-desc">
            <div class="item-desc-title">推荐星级：</div>
            <div class="item-desc-detail">
              <van-icon name="star" v-for="star in item.star" :key="star"/>
              <van-icon name="star-o" v-for="star2 in (5 - item.star)" :key="star2+'none'"/>
              <div style="color: gray; margin: 0px 5px;">|</div>
              <div style="color: white;background-color: rgb(33, 182, 138); font-size: 13px;padding: 0px 3px;">新</div></div>
          </div>-->
          <div class="item-desc">
            <div class="item-desc-detail">{{ item.organisers }}</div>
          </div>
          <div class="item-desc">
            <div class="item-desc-title">活动时间：</div>
            <div class="item-desc-detail">{{item.actBeginTime | filterDate}} - {{item.actEndTime | filterDate}}</div>
          </div>
<!--          <div class="item-desc">
            <div class="item-desc-title">剩余名额：</div>
            <div class="item-desc-detail">{{ item.storQty - item.buyQty }}</div>
          </div>-->
<!--          <div class="item-desc" style="justify-content: flex-end;margin: 0px auto;">
            <van-button size="small" type="info" @click="toBaodu(item)" style="width: 60px;font-size: 16px;line-height: 34px">报名</van-button>
          </div>-->
        </div>
      </van-list>
    </div>

  </div>
</template>
<script>
import EduNavBar from "@/components/VolunteerEduNavBar.vue";
import {Search, Swipe, SwipeItem, List, Icon, Button} from "vant";
import ActivityApi from "@/api/ActivityApi";
import XEUtils from "xe-utils";
import wx from "weixin-js-sdk";
export default {
  name: 'activitys',
  components: {
    EduNavBar,
    VanSearch: Search,
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem,
    VanList: List,
    VanIcon: Icon,
    VanButton: Button
  },
  filters: {
    filterDate(date) {
      return date != null && date != '' ? XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm') : ''
    }
  },
  data() {
    return {
      show: false,
      condition: '',
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
      list: [],
      banners: [{ imgUrl: '../../../statics/images/ad1.jpg' }]
    }
  },
  methods: {
    /*onLoad() {
      console.log('onLoad.')
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },*/
    onSearch() {
      this.pageInfo.page = 1
      this.list = []
      this.onLoad()
    },
    toBaodu(item) {
      this.$router.push({ name: 'cjhd', query: { activitySettingMasterId: item.activitySettingMasterID } })
    },
    onLoad() {
      this.loading = true
      this.pageInfo.condition.isVolunteer = 1
      ActivityApi.getActivitys(this.pageInfo).then(response => {
        this.loading = false
        console.log(response)
        for(var i = 0; i < response.res.list.length; i++) {
          this.list.push(response.res.list[i])
        }
        if (response.res.isLastPage == 1) {
          this.finished = true
        } else {
          this.pageInfo.page = this.pageInfo.page+1
        }
      }).catch(() => { this.loading = false })
    },
    getActivityBanners() {
      ActivityApi.getActivityBanners().then(response => {
        this.banners = response.res
      })
    },
    toActivity(banner) {
      if (banner.href == null || banner.href == '') {
        return
      }
      location.href = '#/' + banner.href
    },
    setShareConfig() {
      // alert('测试分享。')
      var stitle = '活动报名'

      wx.updateAppMessageShareData({
        title: stitle, // 分享标题
        desc: '粤美·巾帼故事妈妈志愿者平台', // 分享描述
        link: 'https://edu.gdfezx.com/qxapi/edu/wechat/oauthforact?page=activitys', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
        success: function () {
          // 设置成功
          // alert('分享给朋友成功。')
        },
        cancel: function() {
          // alert('cancel');
        },
        fail: function(res) {
          // alert('fail'+JSON.stringify(res));
          console.log(res)
        }
      })

      wx.updateTimelineShareData({
        title: stitle, // 分享标题
        link: 'https://edu.gdfezx.com/qxapi/edu/wechat/oauthforact?page=activitys',// window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
        success: function () {
          // 设置成功
          // alert('分享到朋友圈成功。')
        },
        cancel: function() {
          // alert('cancel');
        },
        fail: function(res) {
          // alert('fail'+JSON.stringify(res));
          console.log(res)
        }
      })
    }
  },
  mounted() {
    this.onLoad()
    // this.getActivityBanners()
    this.setShareConfig()
    var openId = this.$route.query.openid
    // alert(openId)
    if (openId != null && openId != '') {
      sessionStorage.setItem('openId', openId)
    }

  }
}
</script>
<style scoped>
.van-search__action {
  /*background-color: #1989fa;*/
  background-color: red;
  color: white;
  margin-right: 10px;
  margin-left: 10px;
}

.my-swipe {
  /* height: 120px; */
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /* line-height: 120px; */
  text-align: center;/*
  background-color: #39a9ed;*/
}
.swipe-img {
  width: 100%;
}
.activity-list {
  width: 100%;
  background-color: #fafafa;
  overflow: auto;
}

.activity-item {
  width: calc(100% - 20px - 16px);
  background-color: white;
  margin: 10px 8px;
  padding: 10px 10px;
}

.item-title {
  display: flex;
  align-items: center;
  font-size: 19px;
  color: black;
}
.item-title-line {
  width: 3px;height: 20px;margin-right: 8px;
}
.item-desc {
  display: flex;
  font-size: 15px;
  margin: 4px 0px;
}
.item-desc-title {
  width: 80px;

}
.item-desc-detail {
  display: flex;
  color: gray;
  align-items: center;
}
</style>
